body {
  font-family: "Gilroy";
  color: white;
  font-style: normal;
  font-weight: normal;
}
.main-button {
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-size:17px;
  padding: 16px 32px;
  text-decoration:none;
  background: linear-gradient(99.41deg, #2F89FC 0%, #2CBCBC 100%);
  border-radius: 60px;
  box-shadow: unset;
  border: none;
  user-select: none;
}
.main-button:hover {
  background: linear-gradient(99.41deg, #2F89FC 10%, #2CBCBC 90%);
}
.main-button:active {
  background: #2F89FC;
}
.small-button {
  padding: 6px 24px;
}
.main, .all-pages {
  width: 100%;
  height: 100vh;
  position: relative;
  .background-image-div {
    z-index: -2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/bg.jpeg");
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: cover;
  }
  .header-wrapper {
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .title-block {
      align-self: center;
    }
    .header {
      display: flex;
      flex-flow: row;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      height: 64px;
      align-items: center;
      justify-content: space-between;
      padding: 0 55px;

      @media only screen and (max-width: 320px) {
        padding: 0 10px;
      }
    }
    .logo {
      cursor: pointer;
      background-image: url("../../assets/images/mesh.svg");
      width: 56px;
      height: 21px;
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: "";
        background-image: url("../../assets/images/plus_blue.svg");
        width: 24px;
        height: 24px;
        right: -24px;
        top: -4px;
      }
    }
    .all-pages-header {
      border-color: rgba(255, 255, 255, 0.25);
      .logo {
        &:after {
          background-image: url("../../assets/images/plus_white.svg");
        }
      }
    }
    .menu {
      display: flex;
      align-items: center;
      .nav-menu {
        list-style: none;
        li {
          cursor: pointer;
          float: left;
          margin-right: 32px;
        }
        li:active {
          color: #2CBCBC;
        }
      }
    }
  }
  .minheight-header {
    height: unset;
  }
}
.main {
  h1 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 0;
  }
}
.flex-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.all-pages {
  background: linear-gradient(99.41deg, rgba(47, 137, 252, 0.9) 0%, rgba(44, 188, 188, 0.9) 100%);
  .header-wrapper {
    background: none;
    height: unset;
  }
  .small-button {
    background: white;
    color: rgba(47, 141, 248, 1);
  }
}
