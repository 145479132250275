body {
  background-color: #e5e5e5;
}
.preview-page-wrapper {
  size: 21cm 29.7cm;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .prev-navy {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    a {
      opacity: 0.5;
      margin: 50px;
      position: relative;
      text-decoration: none;
      color: black;
      font-weight: 600;
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid black;
        left: -9px;
        top: -17px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
