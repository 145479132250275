.control-panel-wrapper {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #2D3445;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 425px) {
        flex-flow: column;
        align-items: center;
    }

    .title-block {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 16px;
        border-right: 1px solid rgba(45, 52, 69, 0.1);

        img {
            margin-right: 5px;
        }
        @media only screen and (max-width: 768px) {
            border-right: none;
        }
    }
    .title-button {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #2F89FC;
        transition: 0.3s;
        &:hover {
            background-color: #dedede;
        }
        cursor: pointer;
    }
    .end-block {
        background-color: #2F89FC;
        color: white;
        padding: 16px;
        cursor: pointer;
    }
    .button-disabled {
        background-color: #dedede;
        cursor: default;
    }

    .actions-block {
        display: flex;
        flex-flow: row;
        align-self: center;
        justify-self: flex-start;
        margin-right: auto;
        padding-left: 16px;

        @media only screen and (max-width: 768px) {
            // flex-flow: column;
            justify-self: center;
            margin-right: unset;
            padding: 3px 0;
        }
    }
    .actions-block-result {
        margin: unset;
        margin-left: auto;
    }

    .control-button {
        cursor: pointer;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: white;
        position: relative;
        border-radius: 3px;
        background-color: #2F89FC;
        padding: 8px 13px 8px 13px;
        mix-blend-mode: normal;
        margin-right: 8px;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        align-items: center;
        &:before {
            content: "";
            display: block;
            margin-right: 10px;
            left: 10px;
            top: 10px;
        }
        @media only screen and (max-width: 320px) {
            &:before {
                margin-right: 0;
            }
            &:after {
                display: none;
            }
        }
    }
    .control-button-last {
        margin-right: unset;
    }
    .button-undo {
        &:before {
            width: 10px;
            height: 12px;
            background-image: url("../../../../assets/images/undo.svg");
            background-repeat: no-repeat;
        }
        &:after {
            content: "Undo";
        }
    }
    .button-reset {
        &:before {
            width: 10px;
            height: 12px;
            background-image: url("../../../../assets/images/reset.svg");
        }
        &:after {
            content: "Reset";
        }
    }
    .button-close {
        &:before {
            width: 4px;
            height: 12px;
            background-image: url("../../../../assets/images/close.svg");
        }
        &:after {
            content: "Close Shape";
        }

    }
    .button-download {
        &:before {
            width: 12px;
            height: 14px;
            background-image: url("../../../../assets/images/download.svg");
            background-repeat: no-repeat;
        }
        &:after {
            content: "Download proposal";
        }
    }
    .button-send {
        &:before {
            width: 12px;
            height: 10px;
            background-image: url("../../../../assets/images/send.svg");
        }
        &:after {
            content: "Submit to Mesh++";
        }
    }
    .button-call {
        &:before {
            width: 12px;
            height: 14px;
            background-size: cover;
            background-image: url("../../../../assets/images/phone.svg");
        }
        &:after {
            content: "Schedule call";
        }
    }
    .button-preview {
        &:before {
            width: 16px;
            height: 16px;
            background-image: url("../../../../assets/images/preview.svg");
        }
        &:after {
            content: "Preview";
        }

    }
    .control-button-disabled {
        background-color: #dedede;
        cursor: default;
    }

    .stats-block {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;

        @media only screen and (max-width: 425px) {
            flex-wrap: wrap;
        }

        .stats-item {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            padding: 16px;

            .title {
                font-family: Gilroy;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: rgba(45, 52, 69, 0.5);
                margin-right: 5px;
            }
        }
    }
}

.control-disabled {
    .end-block {
        background-color: #dedede;
        cursor: default;
    }
    .control-button {
        background-color: #dedede;
        cursor: default;
    }
}
