.pdf-page-wrapper {
  position: relative;
  width: 100%;
  height: 1123px;
  max-width: 21cm;
  // max-height: 29.7cm;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .bottom-part, .center-part {
    z-index: 2;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .bottom-part {
    margin-top: auto;
  }
  .center-part {
    margin-top: 600px;
  }

  .background-div {
    width: 21cm;
    height: 29.7cm;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    background: url('../../../assets/images/forPdf/mp-background.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:before {
      z-index: 1;
      width: 21cm;
      height: 29.7cm;
      position: absolute;
      display: block;
      content: "";
      // opacity: 0.3;
      background: url("../../../assets/images/forPdf/mp-background-gradient.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  h4 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  h2 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
  }

  .preview-topbar {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-top: 35px;
    .date-info {
      color: white;
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: right;
    }
  }
  .preview-footer {
    font-family: Proxima Nova;
    font-weight: 600;
    margin-top: auto;
    padding-bottom: 35px;
    color: white;
    .current {
      color: #2f89fc;
      font-weight: 700;
    }
  }

  .page-wrapper {
    border-bottom: 1px solid #7f7d7d1f;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .preview-content {
      width: 80%;
      display: flex;
      flex-flow: column;
      padding-top: 55px;
    }
  }
  .page3-wrapper {
    .background-div {
      background: url("../../../assets/images/forPdf/back3.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      &:before {
        background: linear-gradient(99.41deg, rgba(47, 137, 252, 0.9) 0%, rgba(44, 188, 188, 0.9) 100%);
      }
    }
    .battery-background {
      background: url('../../../assets/images/forPdf/bg-battery-node.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .urban-solar-background {
      background: url('../../../assets/images/forPdf/bg-solar-urban.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .preview-content {
      align-items: flex-start;
    }
  }
  .preview-list {
    max-width: 350px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 5px;
    padding-right: 15px;
    ul {
      list-style: none;
      li {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        padding-bottom: 5px;
        position: relative;
        &:before {
          position: absolute;
          left: -15px;
          top: 7px;
          content: "";
          display: block;
          border-radius: 7px;
          width: 7px;
          height: 7px;
          background-color: #2CBCBC;
        }
      }

    }
    p {
      padding: 0 20px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      padding-bottom: 5px;
    }
  }
  .page2-wrapper {
    .background-div {
      background: #FFFFFF;
      &:before {
        background: none;
      }
    }

    .date-info {
      color: rgba(8, 63, 94, 0.5);
    }
    .preview-footer {
      color: #7F7D7D;
      text-align: center;
    }
    .preview-content {
      .title {
        font-family: Proxima Nova;
        color: rgba(127, 125, 125, 1);
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-bottom: 35px;
        h2 {
          margin: 0;
          color: rgba(127, 125, 125, 1);
        }
        .info-block {
          display: flex;
          flex-flow: row nowrap;
        }
        span {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding-left: 10px;
        }
        img {
          padding-right: 5px;
          width: 20px;
        }
      }
      .second-title {
        margin-top: 20px;
        padding-bottom: 20px;
      }
      .blue-title {
        h2 {
          color: #1f9acf;
        }
      }
      .no-padding {
        padding: 0px;
      }

      .map-wrapper {
        position: relative;
        display: flex;
        align-self: center;
        justify-self: center;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        max-width: 21cm;
        .legend {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;

          .info-wrapper {
            position: relative;
            margin: 0 5px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: white;

            div {
              z-index: 999;
              display: flex;
              align-items: center;
              flex-flow: column;
              background: linear-gradient(5.71deg, rgba(47, 137, 252, 0.75) 0%, rgba(44, 188, 188, 0.75) 100%);
              padding: 10px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              span {
                color: white;
              }
              .count {
                font-family: Roboto Mono;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0em;
                text-align: center;
              }
              .info-title {
                font-family: Gilroy;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0em;
                text-align: center;
              }
            }
          }
        }
        .cover-div {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          margin-top: 48px;
          position: absolute;
          display: flex;
          width: 100%;
          height: calc(100% - 48px);
          background-color: #00000042;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        }
      }
      .gmap {
        display: flex;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 850px;
        width: 100%;
        position: relative;
        overflow: hidden;
      }
    }
    .content {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      color: #7F7D7D;

      .blue-content {
        color: #2F89FC;
      }
    }
  }
  .page-divider {
    width: 100px;
    height: 4px;
    background: #2F89FC;
    border-radius: 2px;
  }
  .tarifs {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin: -10px;
    .tarif-wrapper {
      padding: 10px;
      margin: 10px;
      background-color: rgba(45, 52, 69, 0.05);
      border-radius: 10px;
    }

    .tarif-title-wrapper {
      border-radius: 5px;
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      color: white;
      .tarif-title {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        width: 100%;
        padding: 10px;
      }
      .tarif-cost {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: bold;
        margin-left: auto;
        padding: 10px;
        border-left: 2px solid rgba(255, 255, 255, 0.25);
      }
    }
    .tarif-second-title {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: bold;
      padding: 10px 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      min-height: 80px;
    }
    .tarif-divider {
      background-color: #2CBCBC;
      width: 100%;
      height: 2px;
      border-radius: 2px;
    }
    .tarif-description {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
    }
    .bronze-tarif {
      .tarif-title-wrapper {
        background: linear-gradient(92.29deg, #CD7F32 0%, #FFE398 100%);
      }
      .tarif-second-title {
        color: #CD7F32;
      }
      .tarif-divider {
        background-color: #CD7F32;
      }
    }
    .silver-tarif {
      .tarif-title-wrapper {
        background: linear-gradient(92.29deg, #E6DADA 0%, #868F92 100%);
      }
      .tarif-second-title {
        color: #868F92;
      }
      .tarif-divider {
        background-color: #868F92;
      }
    }
    .gold-tarif {
      .tarif-title-wrapper {
        background: linear-gradient(92.29deg, #FDC830 0%, #FCE56B 100%);
      }
      .tarif-second-title {
        color: #FDC830;
      }
      .tarif-divider {
        background-color: #FDC830;
      }
    }
  }
  .preview-table {
    max-width: 634px;
    font-size: 15px;
    width: 100%;
    display: table;
    font-family: Proxima Nova;
    font-style: normal;
    color: #2D3445;
    .row {
      display: table-row;
    }
    .row-head {
      .col {
        font-weight: bold;
      }
    }
    .col {
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      white-space: nowrap;
      word-break: unset;
      display: table-cell;
      padding: 10px;
      vertical-align: middle;
    }
    .row {
      .col:first-child {
        width: 100%;
        padding-left: 0;
      }
      .col:last-child {
        padding-right: 0;
        text-align: right;
      }
      &:first-child {
        .col {
          border-top: none;
        }
      }
    }
    .row-t-p {
      .col {
        border-top: 2px solid #2F89FC !important;
      }
    }
    .col-t-p {
      font-weight: bold;
      color: #FFFFFF;
      background-color: #2F89FC;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      text-align: center !important;
    }
    .in-table-list {
      list-style: none;
      padding-left: 20px;
      color: #7F7D7D;
    }
    .blue-col {
      color: #2F89FC;
    }
  }
  .total-price {
    border-top: 2px solid #2F89FC;
    display: flex;
    .price {
      font-weight: bold;
      color: #FFFFFF;
      margin-left: auto;
      padding: 10px;
      background-color: #2F89FC;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
.img-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;

  .nodes-wrapper {
    img {
      padding: 10px;
    }
  }
  .img-container {
    position: relative;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    div {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
    }
    div:nth-child(1) {
      width: 86%;
      left: 54px;
      top: -13px;
    }
    div:nth-child(2) {
      width: 15%;
      left: 276px;
      top: -14px;
    }
  }
}
.app-dash-img-1 {
  background: url('../../../assets/images/forPdf/app-dash1.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  position: relative;

  &:after {
    position: absolute;
    right: -8px;
    bottom: -30px;
    content: "";
    background: url('../../../assets/images/forPdf/done.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
  }
  .texts-wrapper {
    position: absolute;
    bottom: 40px;
    left: 0;
    display: flex;
    flex-flow: row;
    // align-items: center;
    justify-content: space-between;
    width: 590px;
    margin-left: 15px;
    font-family: Proxima Nova;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;


    div {
      // margin: 10px;
      // border: 1px solid rosybrown;
      width: 130px;
      text-align: center;
    }

    .bt-icon {
      color: #1F9ACF;

      &:before {
        content: "";
        display: inline-flex;
        background: url('../../../assets/images/forPdf/app-bt-icon.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 17px;
        height: 17px;
        padding-bottom: 5px;
      }
    }
  }
}

.app-dash-2 {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: -6px;
  .blue-content {
    color: #1f9acf !important;
  }
  h2 {
    color: #1f9acf !important;
  }
  .app-dash-img-2 {
    background: url('../../../assets/images/forPdf/app-dash-img-2.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 170px;
  }
  .app-dash-2-text {
    margin-top: 10px;
    font-family: Proxima Nova;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.app-dash-3 {
  display: flex;
  flex-flow: row;
  margin-top: 60px;
  width: 100%;
  position: relative;
  /*&:after {
    content: "";
    position: absolute;
    background: url('../../../assets/images/forPdf/app-dash-img-3.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 170px;
    right: -248px;
    top: -120px;
  }*/

  font-family: Proxima Nova;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;

  .title-block {
    display: flex;
    align-items: center;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    font-size: 13px;
    color: #1f9acf;
    padding: 15px 15px 15px 0;
    h2 {
      margin: 0;
    }
  }
  .subtitle-block {

    border-top: 1px solid #000000;
    padding: 15px 0 15px 15px;
    font-size: 13px;
    span {
      font-weight: bold;
    }
  }
}

.app-dash-4 {
  padding-top: 10px;
  border-top: 1px solid #000000;
  font-family: Proxima Nova;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;

  span {
    font-weight: bold;
  }
  p {
    padding: 0px;
  }
}

.app-dash-img-4 {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background: url('../../../assets/images/forPdf/app-dash-img-4.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 407px;
    right: 0;
    top: -80px;
  }
}
.app-dash-5 {
  margin-top: 340px;

  h2 {
    color: #1f9acf;
  }
}
.app-dash-5-bottom {
  display: flex;
  .app-dash-img-5 {
    background: url('../../../assets/images/forPdf/app-dash-img-5.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 347px;
  }

  ol {
    li {
      p {
        position: relative;
        left: 10px;
        margin-bottom: 10px;
      }
    }
    li::marker {
      margin-right: 20px;
      color: #1f9acf;
    }
  }
  .blue-color {
    color: #1f9acf;
    font-weight: bold;
  }
}

.app-dash-6 {

  font-family: Proxima Nova;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  font-size: 17px;

  display: flex;
  flex-flow: row;
  color: #1f9acf;
  margin: 5px 0;
  div:nth-child(2) {
    padding-left: 10px;
  }
  .bordered {
    font-weight: bold;
    border-right: 1px solid #1f9acf;
    padding-right: 10px;
  }
}
.app-dash-60-lists {
  display: flex;
  font-size: 15px;
  p {
    margin: 4px 0 0 15px;
  }
  ul {
    margin-bottom: 0;
    margin-top: 0;
    padding-inline-start: 15px;
    li {
      p {
        margin: 3px;
        position: relative;
        left: -3px;
      }
    }
    li::marker {
      margin-right: 0;
    }
  }
  ul:not(:first-child) {
    margin-left: 15px;
    margin-top: 8px;
  }
  .blue-markers {
    color: #1f9acf;
  }
}
.blue-title {
  color: #1f9acf;
}
.app-dash-60-bottom {
  display: flex;
  flex-flow: row;
}
.table-title {
  padding: 5px 3px 3px 3px;
}
.table {
  font-size: 13px;
  display: table;
  border: 2px solid #DBDBDB;
  .t-row {
    display: table-row;

    .t-col {
      min-width: 200px;
      padding: 3px;
      display: table-cell;
    }
    .t-col:first-child {
      width: 150px;
    }
    .t-col:last-child {
      width: 270px;
    }
  }
  .ogg {
    background-color: #DBDBDB;
  }
}
.app-dash-img-6 {
  margin-top: 20px;
  background: url('../../../assets/images/forPdf/app-dash-img-6.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}
.blue-color {
  color: #1f9acf;
}
