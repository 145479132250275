
.loader-wrapper {
  border-radius: 5px;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  flex-flow: column;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;

  .loading-bar-wrapper {
    position: absolute;
    width: 90%;
    height: 38px;
    top: 15px;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    &:after {
      left: 0px;
      top: 0px;
      position: absolute;
      display: flex;
      content: "Data Processing";
      color: black;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .loading-bar {
      position: absolute;
      -webkit-transition: width 0.3s ease-in-out;
      transition: width 0.3s ease-in-out;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      height: 100%;
      background-color: rgba(47, 137, 252, 0.75);
    }
  }
}
