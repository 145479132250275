.tags-list-5-step {
  display: flex;
  flex-flow: row wrap;
  margin: -4px;

  .tag-item {
    margin: 4px;
    cursor: pointer;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-flow: row;
    flex-wrap: nowrap;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .active {
    background-color: #2CBCBC;
    &:hover {
      background-color: #2cbcbc94;
    }
  }
}
