.react-confirm-alert-blur {
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #2d34459e;
  }
}
.confirm-modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.confirm-modal {
  // padding: 30px;
  background-color: #2CBCBC;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0 10px;

  .modal-title {
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    max-width: 400px;
    text-align: center;
  }
  .modal-actions {
    padding: 10px;
    .main-button {
      font-weight: 600;
      margin: 5px;
      background: unset;
      background-color: #2F89FC;
    }
    .cancel-button {
      background-color: whitesmoke;
      color: #2CBCBC;
    }
  }
}
