.custom-form {
  display: flex;
  flex-flow: column;
  // max-width: 370px;
  align-self: center;

  .input-wrapper {
    margin-bottom: 20px;
    position: relative;
  }
  .error-message {
    left: 0;
    bottom: -20px;
    border-radius: 5px;
    width: 100%;
  }

  button.custom-form-button {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;

    width: fit-content;
    // transition: 0.3s;
    background-color: rgba(0, 0, 0, 0.15);
    text-align: center;
    color: #FFFFFF;
    padding: 12px 32px;
    border: none;
    outline: none;
    height: 40px;
    border-radius: 60px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:disabled {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  input.custom-form-input, input.form-control {

    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    // -webkit-box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0.15);
    -webkit-text-fill-color: #FFFFFF;
    width: 100%;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    height: 40px;
    border: none;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 12px 16px;

    &::-webkit-input-placeholder {
      color: #FFFFFF;
      opacity: 0.75;
    }
    &:-ms-input-placeholder { /* Internet Explorer */
      color: #FFFFFF;
      opacity: 0.75;
    }
    &::placeholder {
      color: #FFFFFF;
      opacity: 0.75;
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  input.form-control {
    padding-left: 48px;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .react-tel-input .selected-flag .arrow.up {
    border-bottom-color: white;
  }
  .react-tel-input .flag-dropdown.open {
    background: transparent;
  }
  .react-tel-input .selected-flag:focus {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .react-tel-input .flag-dropdown {
    background-color: rgba(0, 0, 0, 0.15);
    border-color: transparent rgba(0, 0, 0, 0.15);
    .selected-flag {
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      .arrow {
        border-top-color: white;
      }
    }

    .country-list {
      width: 370px;
      background-color: #2CBCBC;
    }
  }

  /* checkbox start */
  .checkbox-wrapper {
    position: relative;
    p {
      margin-left: 25px;
    }
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkmark-container {
    display: block;
    position: relative;
    margin-right: 27px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkmark-container .form-checkbox {
    z-index: 3;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
  .checkmark {
    // transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .checkmark.checked {
    background-color: white;
  }
  .checkmark-container:hover .form-checkbox ~ .checkmark {
    background-color: white;
  }

  .checkmark-container .form-checkbox:checked ~ .checkmark:after {
    content: "";
    display: block;
  }

  .checkmark-container .checkmark:after {
    left: 3px;
    top: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2F89FC;
  }

  .form-checkbox-disabled {
    opacity: 0.5;
  }
  /* checkbox end */
  .bubble-wrapper {
    position: absolute;
    left: -248px;
    top: -25px;
  }
  .bubble {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    background: rgba(0, 0, 0, 0.15);
    color: #FFFFFF;
    // line-height: 120px;
    display: flex;
    align-items: center;
    flex-flow: row;
    padding: 10px;
    width: 213px;
    // height: 120px;
    border-radius: 10px;
    // padding: 0px;
  }
  .bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.15);
    border-width: 10px 0 10px 10px;
    top: 50%;
    right: -10px;
    margin-top: -10px;
  }
}
