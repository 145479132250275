.map-page {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 100px;

    @media only screen and (max-width: 1024px) {
        padding: 20px;
    }

    .map-wrapper {
        position: relative;
        display: flex;
        align-self: center;
        justify-self: center;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        overflow: hidden;
        margin: 0 40px;

        @media only screen and (min-width: 1920px) {
            margin: 0 200px;
        }

        @media only screen and (max-width: 768px) {
            margin: 0;
        }

        .legend {
            padding: 0 15px;
            position: absolute;
            height: 40px;
            left: 20px;
            top: 20px;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-around;
            color: black;

            span:not(:first-child) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                &:before {
                    content: "";
                    background-image: url("../../../assets/images/repeater.svg");
                    display: block;
                    width: 16px;
                    height: 16px;
                    padding-right: 10px;
                    background-repeat: no-repeat;
                }
            }
            span.gateway {
                padding-left: 15px;
                &:before {
                    background-image: url("../../../assets/images/gateway.svg");
                }
            }
            span.sq {
                &:before {
                    display: none;
                }
                margin-right: 10px;
            }
        }
        .map-type-control {
            padding: 0 15px;
            position: absolute;
            height: 40px;
            right: 20px;
            top: 20px;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-around;
            color: black;
            cursor: pointer;
        }
        .map-type-control.disabled {
            opacity: 0.3;
            cursor: default;
        }
        .cover-div {
            font-family: Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-top: 48px;
            position: absolute;
            display: flex;
            width: 100%;
            height: calc(100% - 48px);
            background-color: #00000042;
            flex-flow: column;
            align-items: center;
            justify-content: center;
        }
    }
    .center-text {
        display: none;
    }
    .map-wrapper-start {
        .center-text {
            position: absolute;
            top: 50%;
            display: block;
            color: white;
            width: 100%;
            text-align: center;
            font-family: Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
        background-color: black;
        border-radius: 5px;

        .gmap {
            opacity: 0.3;
        }
    }
    .gmap {
        display: flex;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 850px;
        width: 100%;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: 1920px) {
            height: 650px;
        }
        @media only screen and (max-width: 1440px) {
            height: 500px;
        }
        @media only screen and (max-width: 1024px) {
            height: 400px;
        }
        @media only screen and (max-width: 768px) {
            height: 350px;
        }
    }
}
.map-page-iframe {
    padding: 10px;
    height: calc( 100% - 20px );
    .map-wrapper {
        height: 100%;
        margin: 0;
        .gmap { height: 100%; }
    }
}

